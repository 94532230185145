body {
  margin: 0;
  color: #353d49 !important;
  overscroll-behavior-y: none;
  background-color: #f5f5f5 !important;
}

@media screen and (max-width: 480px) {
  body {
    -webkit-text-size-adjust: none;
  }
}

/* @media screen and (max-device-height: 900px) {
  body {
    min-height: 900px;
  }
} */

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* overflow-y: hidden; */
}

/* header section */

.nav-shadow {
  box-shadow: 0px 1px 6px rgba(150, 150, 150, 0.3);
  background-color: #333f48;
}

.medium-shadow {
  box-shadow: 0px 4px 4px rgba(200, 200, 200, 0.25);
}

.header-container {
  box-shadow: 0px 1px 6px rgba(150, 150, 150, 0.3);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 0;
}

.navbar {
  justify-content: center !important;
}

.predictions {
  font-weight: 500;
  line-height: 20px;
  color: #353d49;
  font-size: 1.3em;
}

/* matches section*/
.mtches-body {
  padding: env(safe-area-inset-top, 20px);
  background-color: #f5f5f5;
}


@media all and (min-width: 768px) {
  .mtches-body {
    margin-left: 7em;
  }
}


@media all and (max-width: 768px) {
  .edit-prediction-btn {
    display: none;
  }
} 

.match-box {
  position: relative;
  border: 1px solid #f5f5f5;
  /* min-width: 100%; */
  background-color: white;
  /* background-size: 100%; */
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0.5em;
}

/* accumulator match section */

.small-shirt {
  height: 18px;
  width: 16px;
}

.accum-dollar-bet {
  margin-top: 225px;
  background-color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-top: 20rem;
}

@media all and (max-width: 768px) {
  .accum-dollar-bet {
    margin-top: 320px;
  }
}
@media all and (max-width: 550px) {
  .accum-dollar-bet {
    margin-top: 295px;
  }
}
@media all and (max-width: 425px) {
  .accum-dollar-bet {
    margin-top: 280px;
  }
}
@media all and (max-width: 375px) {
  .accum-dollar-bet {
    margin-top: 270px;
  }
}

.accum-dollar-bet-no-banner {
  margin-top: 150px;
  background-color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.accum-matches-box-headers {
  background-color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7f8287;
}

.green-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #79c000;
}

.accum-matches-box {
  background-color: #fff;
  font-size: 12px;
  line-height: 14px;
}

.accum-matches-box-numbers {
  position: relative;
  top: 10px;

  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.vi-box {
  margin-right: 3em;
}

.vi {
  position: absolute;
  margin-left: 9px;
  margin-top: 8px;
}
.viCircle {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
}

.accum-padd {
  margin: 0;
}

.banner {
  /* width: 97%; */
  max-width: 1200px;
  border-radius: 10px;
}

.header-banner {
  background-color: #ececec;
}

@media all and (min-width: 768px) {
  .banner {
    /* width: 700px; */
  }
}
/* interactive predictions */

.prediction-box {
  width: 2em;
  padding-top: 0.5em;
}

.prediction-num-wrapper {
  background-color: white;
  height: 2rem;
  width: 2rem;
  border-radius: 7px;
  border: 1px solid #dadada;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prediction-num {
  display: block;
  font-weight: 700;
}

.predictionBtn {
  height: 2em;
  width: 2em;
  margin: 0.3em 0;
}
.arrows-box {
  position: relative;
  height: 2em;
  width: 2em;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  background-color: #F7F7F7;
  outline: none;
  border-radius: 50%;
}

.arrows-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.team-container {
  padding: 0 1rem;
  width: 6.5rem;
  position: relative;
  bottom: 0.5rem;
}

.team-img {
  height: 3.75rem;
  margin: 0 auto;
  display: block;
}

/* end of interactive predictions */

/* --vs image and text centering-- */

.vs-wrapper {
  background-image: url("./images/match-box/vsDecoration.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 80px;
}
.vs {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  color: #353d49;
  margin-top: 5px;
}

/* --end of vs image and text centering-- */

.match-time {
  font-size: 14px;
  line-height: 17px;
  color: #353d49;
  margin-bottom: 0.5em;
}

.date {
  font-size: 14px;
  font-weight: 400;
  color: rgba(53, 61, 73, 0.6);
}

.team-name-container {
  width: 100%;
  height: 0;
}

.team-name {
  width: 100%;
  color: #353d49;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  width: 100%;
  text-align: center;
}
/* end of matches section*/

/* bottom of the page */

.footer {
  background-color: #fff;
  box-shadow: 0px 1px 6px rgba(150, 150, 150, 0.3);
}

.footer-img {
  padding-top: 10px;
  width: 30px;
  height: 34px;
}

.forgot-password-footer-text {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 57.47%
  );
  font-weight: bold;
  font-size: 0.8em;
  padding: 1em;
  color: #ffffff;
}

.forgot-password-footer-text:hover {
  color: #ffffff;
  text-decoration: none;
}

.back-to-login-text {
  margin-left: 20px;
}

.email-sent-text {
  float: right;
  margin-right: 20px;
}

.vi-email-sent {
  margin-left: 18.4em;
}

/* modal styling */

.modal-body {
  padding: 0 !important;
}

.modal-content {
  display: block;
  margin: 0 auto;
  background: linear-gradient(161.5deg, #adff22 9.14%, #7ebc15 83.96%);
  padding: 20px 0px;
  padding-bottom: 0;
  min-height: 300px;
  border-radius: 20px !important;
  border: none !important;
  /* width: 305px !important; */
}
.predictions-modal {
  margin: 0 auto;
}

.modal-vi-div {
  position: relative;
  display: flex;
  margin-left: 15px;
  justify-content: center;
  align-items: center;
}

.circle-img {
  /* position: absolute; */
  /* width: 66px;
  height: 66px; */
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}
.vi-img {
  position: absolute;
  /* margin-top: 20px;
  margin-left: 47px; */
  /* width: 35px;
  height: 27px; */

  justify-content: center;
  align-items: center;
}

.modal {
  margin: 20vh auto 0px auto;
  border-radius: 20px;
}

.success {
  margin-top: 1em;
}
.success-text {
  float: right;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 34px;
}
.trophy-img {
  float: left;
  margin-top: 5px;
  margin-right: 10px;
}

.prediction-submitted {
  font-size: 14px;
  line-height: 17px;
  color: #353d49;
  text-align: center;
  margin-left: 10px;
}

.next-link-div {
  text-align: center;
  margin-top: 25px;
  background-color: #8ec72d;
  padding: 25px;
  border-radius: 0px 0px 20px 20px;
}

.next-link {
  color: white;

  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.next-link:hover {
  text-decoration: none;
  color: white;
}
.next-link:active {
  color: white;
}

.next-link-arrow {
  margin-left: 10px;
  margin-bottom: 2px;
}
/* end modal */

.spinner {
  margin-left: 45%;
  margin-top: 400px;
}

/*signin signup */

/* switch button */
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #79c000 !important;
}
/* // */

.signup-header {
  background-color: rgba(53, 61, 73, 1);
  text-align: center;
}

.signup-logo {
  padding-top: 3em;
}

.signup-text {
  color: white;
  padding-bottom: 0.5em;
  padding-top: 0.7em;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
}

.signup-decoration-line {
  border-top: 2px solid rgb(159, 172, 190);
  margin: 15px 0;
}

.text-field-div fieldset {
  border-radius: 20px;
}

.footer-link:hover {
  text-decoration: none !important;
}

input.editCheckbox:checked + label.editButton {
  color: rgb(53, 61, 73, 0.2);
  background-color: white;
}

.editButton {
  width: 5.7rem;
  height: 2.4rem;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;
  font-family: sans-serif;
  color: #353d49;
  border-radius: calc(2.4rem / 2);
  border: 1px solid #d7d8db;
  user-select: none;
  background-color: rgba(188, 223, 128, 0.671);
}

.match-success-points-container {
  z-index: 999;
  position: absolute;
  right: 0;
  top: 0;
  height: 8rem;
  width: 8rem;
  text-align: center;
  transform: translate(50%, -50%) rotate(45deg);
  transform-origin: 50% 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  line-height: 0.9rem;
  padding-bottom: 0.3rem;
}

.match-success-points-container__correct-score {
  background: linear-gradient(135deg, #1e6f03 50%, #1ec22e 100%);
}

.match-success-points-container__correct-result {
  background: linear-gradient(90deg, #69a700, #99f300);
}

.round-summary_hl {
  font-size: 0.9rem;
  font-weight: 500;
  width: 100%;
  text-align: center;
  color: #a7a7a7;
  padding: 1rem 0;
}

.round-summary_container {
  background-color: white;
  box-shadow: 0px 4px 5px 0px rgba(118, 118, 118, 0.1);
  border-radius: 10px;
  padding: 1rem 0.8rem;
}

.round-summary_head,
.round-summary_row {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: 500;
  font-size: 0.8rem;
}

.round-summary_head {
  color: rgba(167, 167, 167, 1);
}

.round-summary_row:not(:last-child),
.round-summary_head {
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 1rem;
}

.round-summary_row:not(:first-child) {
  padding-top: 1rem;
}

.round-summary_row:last-child {
  font-weight: 700;
}

.round-summary_cell {
  flex: 1;
}

.styled-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.styled-scrollbar::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}

.styled-scrollbar::-webkit-scrollbar-thumb {
  background-color: #79c000;
  border-radius: 2px;
}

.flex-1 {
  flex: 1;
  margin: 0 auto;
}

.flex-2 {
  flex: 2;
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 15px;
  height: 2.6rem;
}
