.sidenav-desktop-container {
  display: flex;
  flex-direction: column;
  background-color: #353d49;
  width: 110px;
  min-height: 100%;
  position: fixed;
  z-index: 2000;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
}
.sidenav-desktop-container::-webkit-scrollbar{
  display: none;
}

.dektop-sidenav-img {
  text-align: center;
}

.desktop-sidenav-logo-container,
.desktop-sidenav-logo-container-active {
  padding: 10px;
}

.desktop-sidenav-logo-container-active {
  background-color: #474e59;
}

.desktop-sidenav-logo-container-active:active,
.desktop-sidenav-logo-container:active {
  background-color: #1d2127;
}

.img-width {
  width: 41 !important;
}
