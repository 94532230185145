.container__Maintenance {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    overflow-y: hidden;
    scroll-behavior: unset;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image__Maintenance {
    width: 100%;
    height: 100%;
}