.component-container {
  padding-top: env(safe-area-inset-top, 50px);
  background-color: #333f48;
  min-height: 100%;
  color: white;
}

/* header section */
.mobile-header {
  background-color: #333f48;
  padding: 1rem 1rem;
  justify-content: space-between;
  position: relative;
  z-index: 3000;
}

.desktop-header {
  padding-top: 2em;
}

.desktop-headline {
  font-size: 22px;
  font-weight: bold;
}

.desktop-flex-1 {
  flex: 1;
}

.desktop-flex-2 {
  flex: 2;
}

.header-image-container {
  align-items: center;
}

.header-image {
  /* text-align: center; */
  /* align-self: center; */
  margin-top: 6px;
}

.back-arrow-container {
}

.back-arrow {
  color: white;
  font-size: 2em;
}

/* first-section */

.section-one {
  position: relative;
  display: flex;
}

.big-image {
  width: 100%;
  z-index: 0;
}

.section-one-general-text {
  position: absolute;
  width: 100vw;
  bottom: 10px;
  min-height: 170px;
  background: rgba(16, 20, 22, 0.85);
  text-align: center;
  padding: 20px;
}

/* @media all and (min-width: 375px) {
  .section-one-general-text {
    bottom: 170px;
  }
} */
@media all and (min-width: 768px) {
  .section-one-general-text {
    bottom: 170px;
  }
}
@media all and (min-width: 1200px) {
  .section-one-general-text {
    bottom: 265px;
  }
}
@media all and (min-width: 1600px) {
  .section-one-general-text {
    bottom: 370px;
  }
}

.inner-box-section-one {
  max-width: 500px;
  margin: 0 auto;
}

.section-one-white-text {
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.section-one-green-text {
  color: #79c000;
  font-weight: 500;
  font-size: 18px;
}

@media all and (max-width: 380px) {
  .section-one-white-text {
    font-size: 12px;
  }
  .section-one-green-text {
    font-size: 10px;
  }
}

@media all and (min-width: 768px) {
  .section-one-white-text {
    font-size: 20px;
    margin-left: 70px;
  }

  .section-one-green-text {
    font-size: 18px;
    margin-left: 70px;
  }
}

@media all and (min-width: 1200px) {
  .section-one-white-text {
    font-size: 40px;
    margin-left: 70px;
  }

  .section-one-green-text {
    font-size: 35px;
    margin-left: 70px;
  }
  .inner-box-section-one {
    max-width: 750px;
  }
}

/* second section */
.section-two {
  margin: 0 auto;
}
.green-headline {
  color: #79c000;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin: 2em auto;
}

.small-p {
  font-size: 10px;
}

@media all and (min-width: 1200px) {
  .section-two {
    margin-left: 5em;
  }
  .green-headline {
    font-size: 44px;
    margin-left: 70px;
  }

  .logos {
    font-size: 30px;
  }

  .small-p {
    font-size: 24px;
  }
}

.logos-section {
  color: white;
  font-size: 14px;
}

.d-block-paragraphs {
  display: block;
}

.img-logo {
  height: 75px;
  margin-right: 3em;
}

.logos {
  height: 100px;
  padding: 20px;
  margin-bottom: 3em;
  justify-content: center;
  box-sizing: content-box;
}

.clock {
  margin-top: 30px;
}

.premios {
  padding: 30px;
  text-align: center;
}

.premios-headline {
  font-size: 18px;
  color: #79c000;
  font-weight: bold;
}

.premios-headline-2 {
  font-size: 18px;
  color: white;
  font-weight: bold;
  padding: 1rem 2rem;
  text-align: center;
}

@media all and (min-width: 1200px) {
  .premios-headline {
    font-size: 44px;
  }

  .premios-p {
    font-size: 28px;
  }
}

/* section three */
.section-three {
  background: rgba(23, 29, 33, 0.4);
}

@media all and (min-width: 1200px) {
  .section-three {
    font-size: 28px;
  }
}

.section-three-line {
  justify-content: space-around;
}

.section-three-box {
  text-align: center;
  flex: 2;
}

.text-arrow {
  color: #79c000;
  flex: 1;
}

/* section four */

.section-four {
  padding: 3em;
  text-align: center;
}

.last-p {
  padding-bottom: 5rem;
  text-align: center;
  font-size: 8px;
  padding-top: 10px;
}

@media all and (min-width: 1200px) {
  .section-four {
    font-size: 28px;
  }
  .last-p {
    font-size: 16px;
    margin-top: 10px;
  }
}
