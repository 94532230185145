/*CSS for CountDown fixed to bottom (predictions page + mobile)*/
.clock-container-fixedCounter__CountDown {
  width: 100%;
  height: 3.75rem;
  position: fixed;
  z-index: 1030;
  padding: 0.4rem 0.4rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  background-size: cover;
}

.xIcon-fixedCounter__CountDown {
  width: 7px;
  position: absolute;
  right: 0.3rem;
  top: 0.25rem;
}
.xIcon-fixedCounter__CountDown:hover {
  cursor: pointer;
}

.details__CountDown {
  text-align: center;
}

.clock-fixedCounter__CountDown {
  display: flex;
}

.clock-title-fixedCounter__CountDown {
  display: flex;
  color: #ffffff;
  font-size: 1em;
  line-height: 1.18rem;
  font-weight: 500;
  font-family: "Roboto-Condensed";
}

.clock-nextRoundSchedule-fixedCounter__CountDown {
  font-family: "Roboto-Condensed";
  font-weight: 500;
  font-size: 0.87em;
  line-height: 1rem;
  color: #ffffff;
}

.littleWhiteSquere-right-fixedCounter__CountDown {
  position: absolute;
  top: 45%;
  right: 0;
  width: 0.1rem;
  height: 0.35rem;
  background-color: #fff;
}

.littleWhiteSquere-left-fixedCounter__CountDown {
  position: absolute;
  top: 45%;
  left: 0;
  width: 0.1rem;
  height: 0.35rem;
  background-color: #fff;
}

.clockItem-fixedCounter__CountDown {
  background: linear-gradient(
    180deg,
    rgba(201, 201, 201, 1) 50%,
    rgba(224, 224, 224, 1) 50%
  );
  font-family: "Roboto-Condensed-Light";
  font-weight: 200;
  font-size: 1.6rem;
  line-height: 2rem;
  margin: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.05em;
  color: #79c000;
  border-radius: 0.25rem;
  border: 0.06rem solid white;
  box-sizing: border-box;
  padding: 0 0.3rem;
  position: relative;
}

/******CSS for CountDown on top (desktop) *******/

.clock-container__CountDown {
  width: auto;
  padding: 0.4rem 0.4rem;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-size: cover;
}

@media (min-width: 768px) {
  .clock-container__CountDown {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 0;
    background-size: cover;
    border-radius: 0.6rem;

  }
}

.xIcon__CountDown {
  position: absolute;
  top: 7px;
  right: 7px;
}
.xIcon__CountDown:hover {
  cursor: pointer;
}

.clock__CountDown {
  display: flex;
}

.clock-title__CountDown {
  display: flex;
  color: #ffffff;
  font-size: 1.5em;
  line-height: 1.8rem;
  font-weight: 400;
  font-family: "Roboto-Condensed";
}

.clock-nextRoundSchedule__CountDown {
  font-family: "Roboto-Condensed";
  font-weight: 400;
  font-size: 1.25em;
  line-height: 1.4rem;
  color: #ffffff;
}

.clockItem-box__CountDown {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.littleWhiteSquere-right__CountDown {
  position: absolute;
  top: 45%;
  right: 0;
  width: 0.125rem;
  height: 0.75rem;
  background-color: #fff;
}

.littleWhiteSquere-left__CountDown {
  position: absolute;
  top: 45%;
  left: 0;
  width: 0.125rem;
  height: 0.75rem;
  background-color: #fff;
}

.clockItem__CountDown {
  background: linear-gradient(180deg,
    rgba(201, 201, 201, 0.9) 50%,
    rgba(224, 224, 224, 0.9) 50%);
  font-family: "Roboto-Condensed-Light";
  font-weight: 300;
  font-size: 4.75em;
  line-height: 5.1rem;
  margin: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.05em;
  color: #79c000;
  border-radius: 0.25rem;
  border: 0.06rem solid white;
  box-sizing: border-box;
  padding: 0 0.3rem;
  position: relative;
  width: 5rem;
}

.clock-timeText__CountDown {
  font-weight: 500;
  font-size: 0.75em;
  line-height: 0.87rem;
  color: #ffffff;
}
