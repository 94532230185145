.slick-slider {
  overflow: hidden;
}

* {
  -webkit-focus-ring-color: none;
  outline: none;
}


@font-face {
  font-family: "Roboto-Condensed";
  src: url("./fonts/RobotoCondensed.ttf");
}

@font-face {
  font-family: "Roboto-Condensed-Light";
  src: url("./fonts/RobotoCondensed-Light.ttf");
}